/**
 * @file App.jsx
 * @description The main app component.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React, { useEffect } from "react";
import {
  useLocation,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import "./../styles/global.scss";
import Navbar from "./../components/navbar";
import IndexPage from "./index";
import DownloadPage from "./download";
import ChangelogPage from "./changelog";
import FaqPage from "./faq";
import LicensePage from "./license";
import PrivacyPolicyPage from "./privacy";
import LegalNoticePage from "./legalnotice";
import NotFoundPage from "./404";
import Footer from "./../components/footer";

/**
 * @function ScrollToTop
 * @description Scrolls to the top of the page when the route changes.
 * @returns {null} Returns nothing.
 */

function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
}

function App() {
  return (
    <Router>
      <>
        <ScrollToTop />
        <Navbar />
        <Routes>
          <Route exact path="/" element={<IndexPage />} />
          <Route exact path="/download/:app" element={<DownloadPage />} />
          <Route exact path="/changelog/:app" element={<ChangelogPage />} />
          <Route exact path="/faq" element={<FaqPage />} />
          <Route exact path="/license" element={<LicensePage />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route exact path="/legal-notice" element={<LegalNoticePage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </>
    </Router>
  );
}

export default App;
