/**
 * @file footer.jsx
 * @description Footer component.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React from "react";
import { Link } from "react-router-dom";

/**
 * @function Footer
 * @returns {JSX.Element} Footer component.
 */

function Footer() {
  return (
    <footer className="footer">
      <div className="columns">
        <div className="column is-4">
          <a
            href="https://felix-d1strict.de"
            className="brand"
            rel="noopener noreferrer"
          >
            <div className="brand-icon">
              <img
                src="/images/logo_websites.svg"
                alt="Felix | D1strict"
                loading="lazy"
                width="500"
                height="360"
              />
            </div>
          </a>
          <br />
          <div className="brand-text">
            <p>
              CUUGP: Copy User and User Group Permissions for Gallery, Blog, Calendar and Lexicon is a project of Felix | D1strict. With a lot of love and
              coffee.
            </p>
          </div>
        </div>

        <div className="column">
          <div className="content">
            <h3>Legals</h3>
            <ul>
              <li>
                <a href="https://felix-d1strict.de/gtc">GTC</a>
              </li>
              <li>
                <a href="https://felix-d1strict.de/cancellation-policy">
                  Cancellation Policy
                </a>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/legal-notice">Legal Notice</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="column">
          <div className="content">
            <h3>Support</h3>
            <ul>
              <li>
                <Link to="/faq">FAQ</Link>
              </li>
              <li>
                <a href="https://felix-d1strict.de/support">Support</a>
              </li>
              <li>
                <a href="https://gitlab.felix-d1strict.de/">GitLab</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="column">
          <div className="content">
            <h3>Recommendations</h3>
            <ul>
              <li>
                <a
                  href="https://tkirch.dev"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  tkirch.dev
                </a>
              </li>
              <li>
                <a
                  href="https://viecode.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  VieCode
                </a>
              </li>
              <li>
                <a
                  href="https://hanashi.dev"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Hanashi Development
                </a>
              </li>
              <li>
                <a
                  href="https://softcreatr.dev"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  SoftCreatR
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="content has-text-centered">
        <p>
          <b>SoftCreatR and YoureCom are not affiliated with Felix | D1strict.</b><br/>
          <i>
            Copyright &copy; {new Date().getFullYear()} Felix | D1strict, All
            rights reserved.
          </i>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
