/**
 * @file index.jsx
 * @description Home page.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React from "react";
import Meta from "../components/Meta";
import MediumHero from "../components/mediumHero";
import { Link } from "react-router-dom";
import Impressions from "../components/impressions";

/**
 * @function IndexPage
 * @returns {JSX.Element} Home page.
 */

function IndexPage() {
  return (
    <>
      <Meta
        title="Home"
        description="These plugins are the ultimate extension to WoltLab Suite™ Apps. Copying user and user group permissions in a new, easy way."
      />
      <MediumHero
        title="CUUGP: Copy User and User Group Permissions"
        subtitle="These plugins are the ultimate extension to WoltLab Suite™ Apps. Copying user and user group permissions in a new, easy way."
      />
      <div className="section" id="apps">
        <div className="container">
          <h2 className="title is-2 has-text-centered">
            Something for every App
          </h2>
          <div className="tile is-ancestor">
            <div className="tile is-parent">
              <article className="tile is-child box notification is-primary">
                <p className="title">Gallery</p>
                <p className="subtitle">by Felix | D1strict</p>
                <div className="buttons">
                  <Link to="/download/gallery" className="button is-small">
                    <b>Download</b>
                  </Link>
                  <Link
                    to="/changelog/gallery"
                    className="button is-light is-small"
                  >
                    Changelog
                  </Link>
                </div>
              </article>
            </div>
            <div className="tile is-parent">
              <article className="tile is-child box notification is-primary">
                <p className="title">Blog</p>
                <p className="subtitle">by Felix | D1strict</p>
                <div className="buttons">
                  <Link to="/download/blog" className="button is-small">
                    <b>Download</b>
                  </Link>
                  <Link
                    to="/changelog/blog"
                    className="button is-light is-small"
                  >
                    Changelog
                  </Link>
                </div>
              </article>
            </div>
            <div className="tile is-parent">
              <article className="tile is-child box notification is-primary">
                <p className="title">Calendar</p>
                <p className="subtitle">by Felix | D1strict</p>
                <div className="buttons">
                  <Link to="/download/calendar" className="button is-small">
                    <b>Download</b>
                  </Link>
                  <Link
                    to="/changelog/calendar"
                    className="button is-light is-small"
                  >
                    Changelog
                  </Link>
                </div>
              </article>
            </div>
            <div className="tile is-parent">
              <article className="tile is-child box notification is-primary">
                <p className="title">Lexicon</p>
                <p className="subtitle">by Felix | D1strict</p>
                <div className="buttons">
                  <Link to="/download/lexicon" className="button is-small">
                    <b>Download</b>
                  </Link>
                  <Link
                    to="/changelog/lexicon"
                    className="button is-light is-small"
                  >
                    Changelog
                  </Link>
                </div>
              </article>
            </div>
          </div>
          <div className="tile is-ancestor">
            <div className="tile is-parent">
              <article className="tile is-child box has-background-info-dark">
                <p className="title has-text-primary-light">Filebase</p>
                <p className="subtitle has-text-primary-light">
                  by{" "}
                  <a
                    href="https://softcreatr.dev/"
                    className="has-text-link-light"
                    rel="noopener noreferrer"
                  >
                    SoftCreatR
                  </a>
                </p>
                <a
                  href="https://www.woltlab.com/pluginstore/file/5566-copy-filebase-category-permissions/"
                  className="button is-small is-light"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Visit Website
                </a>
              </article>
            </div>
            <div className="tile is-parent">
              <article className="tile is-child box has-background-info-dark">
                <p className="title has-text-primary-light">Forum</p>
                <p className="subtitle has-text-primary-light">
                  by{" "}
                  <a
                    href="https://softcreatr.dev/"
                    className="has-text-link-light"
                    rel="noopener noreferrer"
                  >
                    SoftCreatR
                  </a>
                </p>
                <a
                  href="https://www.woltlab.com/pluginstore/file/4785-copy-forum-permissions/"
                  className="button is-small is-light"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Visit Website
                </a>
              </article>
            </div>
            <div className="tile is-parent">
              <article className="tile is-child box has-background-info-dark">
                <p className="title has-text-primary-light">
                  User Group Permissions
                </p>
                <p className="subtitle has-text-primary-light">
                  by{" "}
                  <a
                    href="https://softcreatr.dev/"
                    className="has-text-link-light"
                    rel="noopener noreferrer"
                  >
                    SoftCreatR
                  </a>
                </p>
                <a
                  href="https://www.woltlab.com/pluginstore/file/4795-copy-usergroup-permissions/"
                  className="button is-small is-light"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Visit Website
                </a>
              </article>
            </div>
            <div className="tile is-parent">
              <article className="tile is-child box has-background-info-dark">
                <p className="title has-text-primary-light">Workplace</p>
                <p className="subtitle has-text-primary-light">
                  by{" "}
                  <a
                    href="https://www.yourecom.de/"
                    className="has-text-link-light"
                    rel="noopener noreferrer"
                  >
                    YoureCom
                  </a>
                </p>
                <a
                  href="https://www.yourecom.de/shop/product/82-workplace-category-copy-rights/?l=2"
                  className="button is-small is-light"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Visit Website
                </a>
              </article>
            </div>
          </div>
          <Impressions />
        </div>
      </div>
    </>
  );
}

export default IndexPage;
