/**
 * @file changelog.jsx
 * @description Changelog page.
 * @author Felix Waßmuth (Felix | D1strict)
 * @license Exclusive property of Felix Waßmuth (Felix | D1strict)
 * @version 0.1.0
 * @since 0.1.0
 * @copyright Felix Waßmuth (Felix | D1strict), 2023
 */

import React from "react";
import { Link, useLocation } from "react-router-dom";
import Meta from "../components/Meta";
import Hero from "../components/hero";
import FullheightHero from "../components/fullheightHero";

/**
 * @function ChangelogPage
 * @description Changelog page.
 * @returns {JSX.Element} Changelog page.
 */

function ChangelogPage() {
  const app = useLocation().pathname.split("/")[2];

  if (
    app !== "blog" &&
    app !== "lexicon" &&
    app !== "calendar" &&
    app !== "gallery"
  ) {
    return (
      <>
        <Meta
          title="404"
          description="The page you are looking for does not exist."
          noindex={true}
        />
        <FullheightHero
          title="404"
          subtitle="The page you are looking for does not exist."
          buttonText="Go back to the homepage"
          buttonLink="/"
          color="is-danger"
        />
      </>
    );
  } else {
    if (app === "gallery") {
      return (
        <>
          <Meta
            title="Changelog"
            description="Get the latest news about CUUGP: Gallery."
          />
          <Hero
            title="Changelog"
            subtitle="Get the latest news about CUUGP: Gallery."
          />
          <div className="section">
            <div className="tabs is-centered">
              <ul>
                <li {...(app === "gallery" ? { className: "is-active" } : {})}>
                  <Link to="/changelog/gallery">Gallery</Link>
                </li>
                <li {...(app === "blog" ? { className: "is-active" } : {})}>
                  <Link to="/changelog/blog">Blog</Link>
                </li>
                <li {...(app === "calendar" ? { className: "is-active" } : {})}>
                  <Link to="/changelog/calendar">Calendar</Link>
                </li>
                <li {...(app === "lexicon" ? { className: "is-active" } : {})}>
                  <Link to="/changelog/lexicon">Lexicon</Link>
                </li>
              </ul>
            </div>
            <div className="container">
              <div className="timeline is-centered">
                <header className="timeline-header">
                  <span className="tag is-medium">Version 1.0.0</span>
                </header>
                <div className="timeline-item">
                  <div className="timeline-marker"></div>
                  <div className="timeline-content">
                    <p className="heading">
                      <span className="tag">1.0.1</span>{" "}
                      <time dateTime="2020-10-14T18:05:32+02:00">
                        14th October 2020
                      </time>
                    </p>
                    <p>
                      <span className="has-text-warning">&bull;</span> The
                      WoltLab Suite™ Core minimum version has been lowered to
                      5.2.0.
                      <br />
                      <span className="has-text-warning">&bull;</span> The
                      WoltLab Suite™ Gallery minimum version has been lowered to
                      5.2.0.
                      <br />
                      <span className="has-text-warning">&bull;</span> Package
                      data has been changed.
                    </p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-marker"></div>
                  <div className="timeline-content">
                    <p className="heading">
                      <span className="tag">1.0.2</span>{" "}
                      <time dateTime="2021-05-22T18:24:14+02:00">
                        22nd May 2021
                      </time>
                    </p>
                    <p>
                      <span className="has-text-warning">&bull;</span> Fixed a
                      bug that caused permissions to be applied only after
                      resaving the respective categories.
                    </p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-marker is-primary"></div>
                  <div className="timeline-content">
                    <p className="heading">
                      <span className="tag is-primary">2.0.0</span>{" "}
                      <time dateTime="2021-07-26T19:01:28+02:00">
                        22nd May 2021
                      </time>
                    </p>
                    <p>
                      <span className="has-text-danger">&bull;</span> The minimum
                      version for the WoltLab Suite™ Core has been raised to
                      5.3.0.
                      <br />
                      <span className="has-text-danger">&bull;</span> The minimum
                      version for the WoltLab Suite™ Gallery has been raised to
                      5.3.0.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else if (app === "blog") {
      return (
        <>
          <Meta
            title="Changelog"
            description="Get the latest news about CUUGP: Blog."
          />
          <Hero
            title="Changelog"
            subtitle="Get the latest news about CUUGP: Blog."
          />
          <div className="section">
            <div className="tabs is-centered">
              <ul>
                <li {...(app === "gallery" ? { className: "is-active" } : {})}>
                  <Link to="/changelog/gallery">Gallery</Link>
                </li>
                <li {...(app === "blog" ? { className: "is-active" } : {})}>
                  <Link to="/changelog/blog">Blog</Link>
                </li>
                <li {...(app === "calendar" ? { className: "is-active" } : {})}>
                  <Link to="/changelog/calendar">Calendar</Link>
                </li>
                <li {...(app === "lexicon" ? { className: "is-active" } : {})}>
                  <Link to="/changelog/lexicon">Lexicon</Link>
                </li>
              </ul>
            </div>
            <div className="container">
              <div className="timeline is-centered">
                <header className="timeline-header">
                  <span className="tag is-medium">Version 1.0.0</span>
                </header>
                <div className="timeline-item">
                  <div className="timeline-marker"></div>
                  <div className="timeline-content">
                    <p className="heading">
                      <span className="tag">1.0.1</span>{" "}
                      <time dateTime="2020-10-14T18:05:32+02:00">
                        14th October 2020
                      </time>
                    </p>
                    <p>
                      <span className="has-text-warning">&bull;</span> The
                      WoltLab Suite™ Core minimum version has been lowered to
                      5.2.0.
                      <br />
                      <span className="has-text-warning">&bull;</span> The
                      WoltLab Suite™ Gallery minimum version has been lowered to
                      5.2.0.
                      <br />
                      <span className="has-text-warning">&bull;</span> Package
                      data has been changed.
                    </p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-marker"></div>
                  <div className="timeline-content">
                    <p className="heading">
                      <span className="tag">1.0.2</span>{" "}
                      <time dateTime="2021-05-22T18:24:14+02:00">
                        22nd May 2021
                      </time>
                    </p>
                    <p>
                      <span className="has-text-warning">&bull;</span> Fixed a
                      bug that caused permissions to be applied only after
                      resaving the respective categories.
                    </p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-marker is-primary"></div>
                  <div className="timeline-content">
                    <p className="heading">
                      <span className="tag is-primary">2.0.0</span>{" "}
                      <time dateTime="2021-07-26T19:01:28+02:00">
                        22nd May 2021
                      </time>
                    </p>
                    <p>
                      <span className="has-text-danger">&bull;</span> The minimum
                      version for the WoltLab Suite™ Core has been raised to
                      5.3.0.
                      <br />
                      <span className="has-text-danger">&bull;</span> The minimum
                      version for the WoltLab Suite™ Gallery has been raised to
                      5.3.0.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else if (app === "lexicon") {
      return (
        <>
          <Meta
            title="Changelog"
            description="Get the latest news about CUUGP: Lexicon."
          />
          <Hero
            title="Changelog"
            subtitle="Get the latest news about CUUGP: Lexicon."
          />
          <div className="section">
            <div className="tabs is-centered">
              <ul>
                <li {...(app === "gallery" ? { className: "is-active" } : {})}>
                  <Link to="/changelog/gallery">Gallery</Link>
                </li>
                <li {...(app === "blog" ? { className: "is-active" } : {})}>
                  <Link to="/changelog/blog">Blog</Link>
                </li>
                <li {...(app === "calendar" ? { className: "is-active" } : {})}>
                  <Link to="/changelog/calendar">Calendar</Link>
                </li>
                <li {...(app === "lexicon" ? { className: "is-active" } : {})}>
                  <Link to="/changelog/lexicon">Lexicon</Link>
                </li>
              </ul>
            </div>
            <div className="container">
              <div className="timeline is-centered">
                <header className="timeline-header">
                  <span className="tag is-medium">Version 1.0.0</span>
                </header>
                <div className="timeline-item">
                  <div className="timeline-marker"></div>
                  <div className="timeline-content">
                    <p className="heading">
                      <span className="tag">1.0.1</span>{" "}
                      <time dateTime="2020-10-14T18:05:32+02:00">
                        14th October 2020
                      </time>
                    </p>
                    <p>
                      <span className="has-text-warning">&bull;</span> The
                      WoltLab Suite™ Core minimum version has been lowered to
                      5.2.0.
                      <br />
                      <span className="has-text-warning">&bull;</span> The
                      WoltLab Suite™ Gallery minimum version has been lowered to
                      5.2.0.
                      <br />
                      <span className="has-text-warning">&bull;</span> Package
                      data has been changed.
                    </p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-marker"></div>
                  <div className="timeline-content">
                    <p className="heading">
                      <span className="tag">1.0.2</span>{" "}
                      <time dateTime="2021-05-22T18:24:14+02:00">
                        22nd May 2021
                      </time>
                    </p>
                    <p>
                      <span className="has-text-warning">&bull;</span> Fixed a
                      bug that caused permissions to be applied only after
                      resaving the respective categories.
                    </p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-marker is-primary"></div>
                  <div className="timeline-content">
                    <p className="heading">
                      <span className="tag is-primary">2.0.0</span>{" "}
                      <time dateTime="2021-07-26T19:01:28+02:00">
                        22nd May 2021
                      </time>
                    </p>
                    <p>
                      <span className="has-text-danger">&bull;</span> The minimum
                      version for the WoltLab Suite™ Core has been raised to
                      5.3.0.
                      <br />
                      <span className="has-text-danger">&bull;</span> The minimum
                      version for the WoltLab Suite™ Gallery has been raised to
                      5.3.0.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else if (app === "calendar") {
      return (
        <>
          <Meta
            title="Changelog"
            description="Get the latest news about CUUGP: Calendar."
          />
          <Hero
            title="Changelog"
            subtitle="Get the latest news about CUUGP: Calendar."
          />
          <div className="section">
            <div className="tabs is-centered">
              <ul>
                <li {...(app === "gallery" ? { className: "is-active" } : {})}>
                  <Link to="/changelog/gallery">Gallery</Link>
                </li>
                <li {...(app === "blog" ? { className: "is-active" } : {})}>
                  <Link to="/changelog/blog">Blog</Link>
                </li>
                <li {...(app === "calendar" ? { className: "is-active" } : {})}>
                  <Link to="/changelog/calendar">Calendar</Link>
                </li>
                <li {...(app === "lexicon" ? { className: "is-active" } : {})}>
                  <Link to="/changelog/lexicon">Lexicon</Link>
                </li>
              </ul>
            </div>
            <div className="container">
              <div className="timeline is-centered">
                <header className="timeline-header">
                  <span className="tag is-medium">Version 1.0.0</span>
                </header>
                <div className="timeline-item">
                  <div className="timeline-marker"></div>
                  <div className="timeline-content">
                    <p className="heading">
                      <span className="tag">1.0.1</span>{" "}
                      <time dateTime="2020-10-14T18:05:32+02:00">
                        14th October 2020
                      </time>
                    </p>
                    <p>
                      <span className="has-text-warning">&bull;</span> The
                      WoltLab Suite™ Core minimum version has been lowered to
                      5.2.0.
                      <br />
                      <span className="has-text-warning">&bull;</span> The
                      WoltLab Suite™ Gallery minimum version has been lowered to
                      5.2.0.
                      <br />
                      <span className="has-text-warning">&bull;</span> Package
                      data has been changed.
                    </p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-marker"></div>
                  <div className="timeline-content">
                    <p className="heading">
                      <span className="tag">1.0.2</span>{" "}
                      <time dateTime="2020-10-22T17:29:33+02:00">
                        22nd October 2020
                      </time>
                    </p>
                    <p>
                      <span className="has-text-warning">&bull;</span> Fixed an
                      error that prevented an update to version 1.0.1 under
                      WoltLab Suite™ Core 5.2.
                    </p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-marker"></div>
                  <div className="timeline-content">
                    <p className="heading">
                      <span className="tag">1.0.3</span>{" "}
                      <time dateTime="2021-05-22T18:24:14+02:00">
                        22nd May 2021
                      </time>
                    </p>
                    <p>
                      <span className="has-text-warning">&bull;</span> Fixed a
                      bug that caused permissions to be applied only after
                      resaving the respective categories.
                    </p>
                  </div>
                </div>
                <div className="timeline-item">
                  <div className="timeline-marker is-primary"></div>
                  <div className="timeline-content">
                    <p className="heading">
                      <span className="tag is-primary">2.0.0</span>{" "}
                      <time dateTime="2021-07-26T19:01:28+02:00">
                        22nd May 2021
                      </time>
                    </p>
                    <p>
                      <span className="has-text-danger">&bull;</span> The minimum
                      version for the WoltLab Suite™ Core has been raised to
                      5.3.0.
                      <br />
                      <span className="has-text-danger">&bull;</span> The minimum
                      version for the WoltLab Suite™ Gallery has been raised to
                      5.3.0.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

export default ChangelogPage;
